import React, { useState, forwardRef } from 'react'
import { useClasses } from '../lib/ReactUtils'


// we need forwardRef for Modal
// eslint-disable-next-line
const VideoComponent = forwardRef(({src, className = 'w-120 md:w-150 lg:w-200 xl:w-250'}, ref) => {
  const root = useClasses()
  const [error, setError] = useState(false)

  if (error) return (
    <div className='my-3 px-2 py-1 text-center' ref={root}>
      <h3 className='font-semibold text-red-500'>Error loading video</h3>
    </div>
  )

  return (
    <video ref={root} className={'relative z-10 ' + className} autoPlay preload="auto" controls>
        { Array.isArray(src) ?
            src.map((cur, index) => <source key={index} src={cur.src} type={cur.type}/>)  :
            <source src={src}/>
        }
    </video>
  )
})

export default VideoComponent
