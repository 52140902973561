import React, { useState, forwardRef, useImperativeHandle, useRef } from 'react'
import { useClasses, usePrevious } from '../lib/ReactUtils'
import { useSwipeable } from "react-swipeable"

export default forwardRef(function Carousel({children:slides, className = 'bg-white opacity-95 rounded-xl'}, ref) {
  const root = useClasses()
  const [index, setIndex] = useState(0)
  const prev = usePrevious(index)
  const prevIndex = useRef(0)
  const nextIndex = useRef(0)

  const handlers = useSwipeable({
    onSwipedLeft: () => { if (nextIndex.current!= -1) setIndex(nextIndex.current) },
    onSwipedRight: () => { if (prevIndex.current!= -1) setIndex(prevIndex.current) },
    preventDefaultTouchmoveEvent: false,
    trackMouse: true
  })

  // eslint-disable-next-line arrow-body-style
  useImperativeHandle(ref, () => ({
    setIndex,
    getIndex: () => index
  }))

  const handleClick = (e) => setIndex(parseInt(e.currentTarget.getAttribute('data-index')))

  const maxIndex = slides.length - 1
  prevIndex.current = index == 0 ? -1 : index - 1
  nextIndex.current = index == maxIndex ? -1 : index + 1


  return (
    <div className="relative overflow-hidden w-full h-full px-2 sm:px-5 lg:px-30" ref={root}>
      {slides.length > 1 ?
        <>
          { prevIndex.current != -1 ?
            <span data-index={prevIndex.current} onClick={handleClick} className="w-14 h-14 ml-4 md:ml-10 absolute cursor-pointer text-3xl text-primary hover:text-white rounded-full bg-white hover:bg-primary leading-tight text-center z-1 inset-y-0 left-0 flex items-center justify-center my-auto">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
              </svg>
            </span> : null
          }
          { nextIndex.current != -1 ?
            <span data-index={nextIndex.current} onClick={handleClick} className="w-14 h-14 mr-4 md:mr-10 absolute cursor-pointer text-3xl text-primary hover:text-white rounded-full bg-white hover:bg-primary leading-tight text-center z-1 inset-y-0 right-0 flex items-center justify-center my-auto">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </span> : null
          }
        </> : null
      }

      <div {...handlers} className={"w-full h-full relative " + className}>
        {slides.map((slide, i) =>
          <div key={i} className={"absolute h-full w-full transition p-2 sm:p-5 lg:p-10 duration-350 " + (i == index ? "opacity-100" : "opacity-0")}>
            <div className='w-full h-full items-center'>
              {slide}
            </div>
          </div>
        )}
      </div>

    </div>
  )
})
