import * as React from "react"
import { useClasses, addClass } from '../lib/ReactUtils'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Carousel from '../components/Carousel'
import Video from '../components/Video'
import { useModal } from '../sections/Modal'
import HERO from '../images/index/hero.svg'
import SECURITY from '../images/index/security.svg'
import APPS from '../images/index/applications.svg'
import COLLABORATION from '../images/index/collaboration.svg'
import HYBRID from '../images/index/hybrid.svg'
import { StaticImage } from "gatsby-plugin-image"
import { Link, useStaticQuery, graphql } from "gatsby"
import CTA from '../components/CTA'
import '../styles/Hero'
import BackgroundImage from 'gatsby-background-image'


addClass('slide-title', 'text-2xl text-center mt-10 font-bold leading-tight tracking-snug')
addClass('slide-body', 'text-xl text-gray-700 text-center leading-tight')

export default function IndexPage() {
  const root = useClasses()
  const modal = useModal()
  const { heroImage } = useStaticQuery(
    graphql`
      query {
        heroImage: file(
          absolutePath: { glob: "**/images/index/hero.jpg" }
          sourceInstanceName: { eq: "images" }   
        ) {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 50) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  const showVideo = () => {
    modal.show({
      component: Video,
      data: {
        src: [{
          src: '/marketing/introduction.mp4',
          type: 'video/mp4'
        }]
      }
    })
  }

  return (
    <Layout full dark>
      <SEO title='Home' image={heroImage.childImageSharp.fluid.src}/>
      <main className="main" ref={root}>

        <BackgroundImage className='hero-container' fluid={heroImage.childImageSharp.fluid}>
          <div className='hero-overlay'/>
          <div className='z-2 max-h-3/4 w-full max-w-screen-xl mt-10 h-130 sm:h-150 xl:h-180'>
            <Carousel>
              {/*
              <div className='h-full w-full flex flex-col justify-center gap-2'>
                <video className='w-full h-full relative z-10' muted autoPlay preLoad="auto" controls>
                    <source src="/intro/intro.webm" type="video/webm"/>
                    <source src="/intro/intro/mp4" type="video/mp4"/>
                </video>
              </div>
              */}
              <div className='h-full w-full flex flex-col justify-center gap-2'>
                <StaticImage src='../images/index/drive-sharing.png' alt='Files' className='rounded'/>
                <h3 className='slide-title'>OneOffice is the secure solution to manage your work</h3>
                <p className='slide-body'>Share your files, work with documents and get notified when work gets done</p>
              </div>
              <div className='h-full w-full flex flex-col justify-center gap-2'>
                <StaticImage src='../images/index/documents.png' alt='Editor' className='rounded'/>
                <h3 className='slide-title'>Edit your documents right in your browser</h3>
                <p className='slide-body'>Edit and collaborate on your Word, Excel, PPT and Visio docs live!</p>
              </div>
              <div className='h-full w-full flex flex-col justify-center gap-2'>
                <StaticImage src='../images/index/videoconference.jpg' alt='Conference' className='rounded'/>
                <h3 className='slide-title'>Secure Video / Audio Calls</h3>
                <p className='slide-body'>Call your colleagues or teams securely on their PC or smartphones</p>
              </div>
              <div className='h-full w-full flex flex-col justify-center gap-2'>
                <StaticImage src='../images/index/email.png' alt='Conference' className='rounded'/>
                <h3 className='slide-title'>Secure Emails</h3>
                <p className='slide-body'>State of the art Email service with cyberprotection built-in</p>
              </div>
              <div className='h-full w-full flex flex-col justify-center gap-2'>
                <StaticImage src='../images/index/calendar.png' alt='Calendar' className='rounded'/>
                <h3 className='slide-title'>Email, Calendar and Contacts</h3>
                <p className='slide-body'>All your groupware apps working seamlessly to make you more productive</p>
              </div>
              {/*
              <div className='h-full w-full flex flex-col justify-center gap-2'>
                <StaticImage src='../images/index/deck.jpg' alt='Deck' className='rounded'/>
                <h3 className='slide-title'>Manage your projects with simple cards</h3>
                <p className='slide-body'>Simple Kanban-style cards makes projects a breeze</p>
              </div>
              */}
              <div className='h-full w-full flex flex-col justify-center gap-2'>
                <StaticImage src='../images/index/dashboard.png' alt='Dashboard' className='rounded'/>
                <h3 className='slide-title'>Start your day on the right foot</h3>
                <p className='slide-body'>See your whole day at a glance, upcoming deadlines and any important notifications!</p>
              </div>
              <div className='h-full w-full flex flex-col justify-center gap-2'>
                <StaticImage src='../images/index/erp.png' alt='ERP' className='rounded'/>
                <h3 className='slide-title'>The smartest yet easiest ERP on the planet!</h3>
                <p className='slide-body'>Streamline your operations, accounting, HR, sales etc. and everything in between</p>
              </div>
            </Carousel>
          </div>
        </BackgroundImage>

        <div className="sm:flex relative items-center overflow-hidden container mx-auto container px-2 relative py-16">
          <div className="w-full sm:w-2/3 lg:w-2/5 flex flex-col relative gap-3">
            <h1 className="text-center uppercase mb-10 text-3xl sm:text-4xl md:text-5xl font-bold leading-tight tracking-tighter text-primary-dark">
              It's All There
            </h1>
            <p className="text-2xl text-gray-800 mb-5 text-center">
              OneOffice brings together <span className='font-bold underline'>all the tools</span> companies need in one easy-to-use application.
            </p>
            <p className="text-center text-xl font-bold text-primary-dark">
              Secure. Simple. Cost-Effective.
            </p>
            <div className="flex mt-8 justify-center gap-3">
              <Link to="/buy" className="button mr-3">Get started</Link>
              <Link to="/demo" className="button cta">Book demo</Link>
            </div>
          </div>
          <div className="w-full sm:w-1/3 lg:w-3/5 relative cursor-pointer">
            <img src={HERO} className="m-auto" onClick={showVideo}/>
          </div>
        </div>

        <div className='bg-indigo-800 text-white'>
          <h1 className="uppercase text-center pt-15 pb-5 text-3xl sm:text-4xl md:text-5xl font-bold">
            Security First
          </h1>
          <div className='container mx-auto sm:flex flex-col gap-4 items-center overflow-hidden px-2 relative pb-20'>
            <div className="w-full sm:w-1/3 lg:w-2/5 relative">
              <img src={SECURITY} className="m-auto"/>
            </div>
            <div className="w-full sm:w-2/3 lg:w-3/5 flex flex-col relative gap-3">
              <p className="text-2xl mb-5 bg-white text-indigo-900 px-2 py-5 text-center rounded">
                How do large companies and governments secure their data? Simple, <span className='font-bold underline'>they don't share</span>
              </p>
              <p className="text-xl font-thin mb-5 px-5 text-center">
                OneOffice was painstakingly built to put security first, by bundling all the tools you need on <span className='font-bold'>your own server on Huawei Cloud</span>.
                Once exclusive to large IT teams, OneOffice is now making security and privacy accessible to all!
              </p>
              <p className="text-xl text-center font-bold px-5">
                Isolation. &nbsp;Encryption. &nbsp;Server-side editing.
              </p>
              <div className="mt-10 mx-auto">
                <Link to="/security" className="button cta">
                  Read about Security
                </Link>
              </div>
            </div>
          </div>      
        </div>

        <div className='text-gray-800 '>
          <h1 className="uppercase text-center pt-15 pb-5 text-3xl sm:text-4xl md:text-5xl font-bold">
            Complete
          </h1>
          <div className='container mx-auto sm:w-4/5 flex flex-col gap-4 relative items-center overflow-hidden px-2 pb-10'>
            <div className="w-full sm:w-4/5 m-auto relative">
              <Link to='/features'><img src={APPS} className="m-auto"/></Link>
            </div>
            <div className="mx-auto ">
              <p className="text-2xl text-center mb-5 text-white bg-gray-800 rounded px-7 py-6">
                OneOffice comes with all the applications your organization needs<br/>
                <span className='font-bold text-2xl'>No third-party licenses ever again</span>
              </p>
            </div>
            <div className="mx-auto ">
              <p className="text-xl text-center mb-5 font-thin">
                ... no more integrations, single signon, upgrades, patches, new servers etc.
              </p>
            </div>
          </div>      
        </div>

        <CTA className='container'/>

        <div className='bg-indigo-200 text-blue-900'>
          <h1 className="uppercase text-center pt-15 pb-10 text-3xl sm:text-4xl md:text-5xl font-bold">
            For Mobile-teams
          </h1>
          <div className='container mx-auto sm:flex gap-4 relative items-center overflow-hidden px-5 relative pb-20'>
            <div className="w-full sm:w-2/5 lg:w-1/2 relative m-auto rounded">
              <StaticImage src="../images/index/devices.png" alt="Devices"/>
            </div>
            <div className="sm:w-3/5 lg:w-1/2 flex flex-col relative text-center">
              <p className="text-xl md:text-2xl mb-5 bg-white text-primary-dark py-6 px-6 rounded">
                OneOffice is a <span className='font-bold'>full suite</span> with built-in video calls, editors, collaboration, emails, calendars, chats, projects etc.
              </p>
              <p className="text-xl mb-5 px-5 font-thin">
                Our mobile apps were specifically designed to make sure you don't miss a beat!
              </p>
              <div className="mt-8 mx-auto">
                <Link to="/download" className="button text-sm md:text-base lg:text-lg">
                  Download your productivity apps!
                </Link>
              </div>
            </div>
          </div>      
        </div>


        <div className='bg-gray-200 text-primary-dark'>
          <h1 className="uppercase text-center pt-15 text-3xl sm:text-4xl md:text-5xl font-bold">
            Collaboration
          </h1>
          <div className='container mx-auto sm:flex gap-5 items-center overflow-hidden px-5 pb-15'>
            <div className="w-full sm:w-1/3 lg:w-2/5 relative m-auto">
              <img src={COLLABORATION} className="m-auto"/>
            </div>
            <div className="w-full sm:w-2/3 lg:w-3/5 px-5 flex flex-col">
              <p className="text-xl md:text-2xl font-bold mb-5">
                Collaboration & teamwork are integrated in ... <span className='underline'>everything</span>!
              </p>
              <p className="text-lg md:text-xl font-thin mb-5">
                Attach documents in chats & calls, link tasks automatically to your calendar, securely share with customers  ...
              </p>
              <p className="text-lg md:text-xl font-thin">
                Comment on anything and get notified as soon as someone mentions @you so you are always in the know!
              </p>    
            </div>
          </div>      
        </div>


        <div className='bg-white text-gray-800'>
          <h1 className="uppercase text-center pt-15 pb-10 text-3xl sm:text-4xl md:text-5xl font-bold">
            Hybrid Cloud
          </h1>
          <div className='container mx-auto sm:flex gap-4 items-center overflow-hidden px-2 relative pb-20'>
            <div className="w-full sm:w-3/5 lg:w-1/2 flex flex-col relative px-5">
              <p className="text-xl md:text-2xl mb-5 bg-primary-dark text-white py-6 px-5 rounded text-center">
                For mission-critical applications local mirror servers achieve zero down-time
              </p>
              <p className="text-lg md:text-xl mb-5 px-5 font-thin text-center">
                All your data is sync-ed real-time, including documents, metadata, chats, calendars etc.
              </p>
              <div className="mt-8 mx-auto">
                <Link to="/hybrid" className="button text-sm md:text-base lg:text-lg">
                  Read about Hybrid Cloud
                </Link>
              </div>
            </div>
            <div className="w-full sm:w-2/5 lg:w-1/2 relative m-auto">
              <img src={HYBRID} className="m-auto"/>
            </div>
          </div>      
        </div>

        <CTA variant={3} className='container'/>

      </main>
    </Layout>

  )
}


